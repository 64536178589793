import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import ImgurClient from 'imgur';
import { Button, Slider } from '@mui/material';
import ProgressModal from './components/ProgressModal';

const GIF = window.GIF;

const GRID_SIZE = 32;
const GIF_RATE = 0.1;   // GIF frame rate in seconds

const STATIC_IMAGE_TYPE = 'STATIC_IMAGE';
const GIF_TYPE = 'GIF';

const FILL_MODE = 'FILL';
const DRAW_MODE = 'DRAW';

// IMGUR DETAILS
const IMGUR_CLIENT_ID = process.env.REACT_APP_IMGUR_CLIENT_ID;
const IMGUR_ALBUM_ID = process.env.REACT_APP_IMGUR_ALBUM_ID;

let pointerMode = DRAW_MODE;
let imageType = STATIC_IMAGE_TYPE;
let totalGIFFrames = 0; // To be updated

/*
* TODO
* Gallery button that shows what everyone else has drawn
* GIF crafting
* PNG uploading to the canvas
* Undo
*/

function App() {
  const canvasRef = useRef(null);
  const fileInputRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  // Array of 2D frames. A static image is a single element array of a 2D frame
  let initialGrid = [];
  initialGrid.push([GRID_SIZE]);
  for(let i = 0; i < GRID_SIZE; i++) {
    initialGrid[0][i] = [GRID_SIZE];
      for(let j = 0; j < GRID_SIZE; j++) {
        initialGrid[0][i][j] = [0, 0, 0, 0];
      }
  }
  const [grid, setGrid] = useState(initialGrid);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [mouseDrag, setMouseDrag] = useState(false);
  const [colorValue, setColorValue] = useState([0,0,255, 255]);
  const [modalProgress, setModalProgress] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  // Initial Canvas setup to resize the canvas based on window mutation
  useEffect(() => {
    const updateCanvasSize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const minDimension = Math.min(width, height);
      const newCanvasSize = { width: minDimension, height: minDimension };
      setCanvasSize(newCanvasSize);
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, []);

  // The redraw event that depends on the Grid and canvas size
  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Clear canvas
    context.clearRect(0, 0, canvas.width, canvas.height);

    // Calculate grid cell size
    const cellSize = canvas.width / GRID_SIZE;

    // Draw alternating squares to represent the transparent cells
    context.fillStyle = 'rgb(230,230,230)';
    for (let x = 0; x < GRID_SIZE; x++) {
      for (let y = 0; y < GRID_SIZE; y++) {
        context.fillRect(x*cellSize, y*cellSize, cellSize/2, cellSize/2);
        context.fillRect(x*cellSize+cellSize/2, y*cellSize+cellSize/2, cellSize/2, cellSize/2);
      }
    }

    // Draw the picture
    for (let x = 0; x < GRID_SIZE; x++) {
      for (let y = 0; y < GRID_SIZE; y++) {
        context.fillStyle = 'rgb(' + grid[currentImageIndex][x][y].join(', ') + ')';
        context.fillRect(x*cellSize, y*cellSize, cellSize, cellSize);
      }
    }

    // Draw grid on top
    context.strokeStyle = 'gray';
    for (let x = 0; x < canvas.width; x += cellSize) {
      context.beginPath();
      context.moveTo(x, 0);
      context.lineTo(x, canvas.height);
      context.stroke();
    }
    for (let y = 0; y < canvas.height; y += cellSize) {
      context.beginPath();
      context.moveTo(0, y);
      context.lineTo(canvas.width, y);
      context.stroke();
    }

    // Distinguish the axes
    context.strokeStyle = 'black';
    context.beginPath();
    context.moveTo(canvas.width/2, 0);
    context.lineTo(canvas.width/2, canvas.height);
    context.stroke();

    context.beginPath();
    context.moveTo(0, canvas.height/2);
    context.lineTo(canvas.width, canvas.height/2);
    context.stroke();
  }, [canvasSize, grid, currentImageIndex]);

  const handleMouseDown = (event) => {
    // Ignore anything but lmb
    const isLmb = event.button === 0;
    if (mouseDrag || !isLmb) return;

    const canvas = canvasRef.current;

    let calcX = parseInt(GRID_SIZE*event.clientX/canvas.width);
    let calcY = parseInt(GRID_SIZE*event.clientY/canvas.height);
    calcX = Math.max(Math.min(calcX, GRID_SIZE-1), 0);
    calcY = Math.max(Math.min(calcY, GRID_SIZE-1), 0);

    const newGrid = [...grid];
    let frame = newGrid[currentImageIndex];

    // If drawing
    if (pointerMode === DRAW_MODE) {
      frame[calcX][calcY] = colorValue;
    }
    // If filling
    else if (pointerMode === FILL_MODE) {
      const targetColor = frame[calcX][calcY]; // The color to be filled
      const fillColor = colorValue;   // The color to fill with
      paintFill(frame, calcX, calcY, targetColor, fillColor);
    }

    setGrid(newGrid);

    setMouseDrag(true);
  }

  const handleMouseMove = (event) => {
    if (!mouseDrag) return;

    const canvas = canvasRef.current;

    let calcX = parseInt(GRID_SIZE*event.clientX/canvas.width);
    let calcY = parseInt(GRID_SIZE*event.clientY/canvas.height);
    calcX = Math.max(Math.min(calcX, GRID_SIZE-1), 0);
    calcY = Math.max(Math.min(calcY, GRID_SIZE-1), 0);

    const newGrid = [...grid];
    let frame = newGrid[currentImageIndex];
    frame[calcX][calcY] = colorValue;
    setGrid(newGrid);
  }

  const handleMouseUp = () => {
    setMouseDrag(false);
  }

  const handleMouseOut = () => {
    setMouseDrag(false);
  }

  const handleTouchStart = (event) => {
    if (mouseDrag) return;

    const canvas = canvasRef.current;
    const touch = event.touches[0];

    let calcX = parseInt(GRID_SIZE*touch.clientX/canvas.width);
    let calcY = parseInt(GRID_SIZE*touch.clientY/canvas.height);
    calcX = Math.max(Math.min(calcX, GRID_SIZE-1), 0);
    calcY = Math.max(Math.min(calcY, GRID_SIZE-1), 0);

    const newGrid = [...grid];
    let frame = newGrid[currentImageIndex];

    // If drawing
    if (pointerMode === DRAW_MODE) {
      frame[calcX][calcY] = colorValue;
    }
    // If filling
    else if (pointerMode === FILL_MODE) {
      const targetColor = frame[calcX][calcY]; // The color to be filled
      const fillColor = colorValue;   // The color to fill with
      paintFill(frame, calcX, calcY, targetColor, fillColor);
    }

    setGrid(newGrid);

    setMouseDrag(true);
  }

  const handleTouchMove = (event) => {
    if (!mouseDrag) return;

    const canvas = canvasRef.current;
    const touch = event.touches[0];
    
    let calcX = parseInt(GRID_SIZE*touch.clientX/canvas.width);
    let calcY = parseInt(GRID_SIZE*touch.clientY/canvas.height);
    calcX = Math.max(Math.min(calcX, GRID_SIZE-1), 0);
    calcY = Math.max(Math.min(calcY, GRID_SIZE-1), 0);

    const newGrid = [...grid];
    let frame = newGrid[currentImageIndex];
    frame[calcX][calcY] = colorValue;
    setGrid(newGrid);
  }

  const handleTouchEnd = () => {
    setMouseDrag(false);
  }

  const PublishImage = (event) => {
    if(imageType === STATIC_IMAGE_TYPE) {
      // TODO, consider pnglib

      // Create a canvas, paint the 2d array pixel data onto it,
      // then get the dataURL from the canvas for the base64 encoded image data
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = GRID_SIZE;
      tempCanvas.height = GRID_SIZE;

      for (let y = 0; y < GRID_SIZE; y++) {
        for (let x = 0; x < GRID_SIZE; x++) {
          const [r, g, b, a] = grid[currentImageIndex][x][y];
          tempCtx.fillStyle = `rgba(${r},${g},${b},${a})`;
          tempCtx.fillRect(x, y, 1, 1);
        }
      }

      // Strip the "data:image/png;base64" prefix
      let imageBase64 = tempCanvas.toDataURL('image/png').split(',')[1];

      // Post to the album
      const client = new ImgurClient({ clientId: IMGUR_CLIENT_ID});
      client.upload({
        image: imageBase64,
        album: IMGUR_ALBUM_ID,
        type: 'base64',
        title: 'Test Title',
        name: 'Test Name',
        description: 'Test Description'
      });
    } else if(imageType === GIF_TYPE) {
      const totalFrames = grid.length;

      // Temp canvas to write to the GIF with
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = GRID_SIZE;
      tempCanvas.height = GRID_SIZE;

      let gif = new GIF({
        workers: 2,
        quality: 10,
        workerScript: process.env.PUBLIC_URL + '/gif.worker.js'
      });

      for(let currFrameIndex = 0; currFrameIndex < totalFrames; currFrameIndex++) {
        // Take the current frame and add it to the ongoing GIF
        for (let y = 0; y < GRID_SIZE; y++) {
          for (let x = 0; x < GRID_SIZE; x++) {
            const [r, g, b, a] = grid[currFrameIndex][x][y];
            tempCtx.fillStyle = `rgba(${r},${g},${b},${a})`;
            tempCtx.fillRect(x, y, 1, 1);
          }
        }

        // Add the frame to the GIF object
        // TODO: see if there's a method that just takes a 2D arr for GIF.js
        gif.addFrame(tempCanvas, {delay: GIF_RATE * 1000, copy: true});
      }

      // Cleanup
      tempCanvas.remove();

      gif.on('finished', function(gifBlob) {
        // window.open(URL.createObjectURL(blob));

        const reader = new FileReader();

        // Handle the 'load' event to convert the blob to Base64
        reader.onload = function () {
          const base64String = reader.result.split(',')[1]; // Extract the Base64 part
          // Post to the album
          const client = new ImgurClient({ clientId: IMGUR_CLIENT_ID});
          client.upload({
            image: base64String,
            album: IMGUR_ALBUM_ID,
            type: 'base64',
            title: 'Test Title',
            name: 'Test Name',
            description: 'Test Description'
          });
        };

        // Read the GIF blob as data URL
        reader.readAsDataURL(gifBlob);
      });
      gif.render();
    }
  }

  const ChangeColor = (event) => {
    // convert the string to an array
    const toRGBArray = rgbStr => rgbStr.match(/\d+/g).map(Number);
    let rgbArr = toRGBArray(window.getComputedStyle(event.target).backgroundColor)
    let newColorValue = [rgbArr[0],rgbArr[1],rgbArr[2],255];
    setColorValue(newColorValue);
  }

  const hexToOpaqueRGB = (hex) => {
    // Remove the '#' character if it's present
    hex = hex.replace('#', '');

    // Convert the hex color to separate RGB components
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return [r, g, b, 255];
  }

  const handleCustomColorChange = () => {
    const colorPicker = document.getElementById('colorPicker');
    setColorValue(hexToOpaqueRGB(colorPicker.value));
  }

  const ChangeToErase = (event) => {
    setColorValue([0,0,0,0]);
  }

  const OnGIFSliderChange = (event) => {
    let sliderTarget = event.target;
    let sliderValue = sliderTarget.value;

    let newImageIndex = Math.floor(sliderValue / 100.0 * (totalGIFFrames-1));
    setCurrentImageIndex(newImageIndex);
  }

  const arrayEquals = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
  }

  const paintFill = (tempFrame, col, row, targetColor, fillColor) => {
    if (row < 0 || row >= GRID_SIZE || col < 0 || col >= GRID_SIZE) {
      return; // Out of bounds
    }
    if (!arrayEquals(tempFrame[col][row], targetColor) || arrayEquals(tempFrame[col][row], fillColor)) {
      return; // Not the target color or already filled
    }
    tempFrame[col][row] = fillColor; // Fill the cell

    // Explore neighbors: Up, Down, Left, Right
    paintFill(tempFrame, col, row - 1, targetColor, fillColor); // Up
    paintFill(tempFrame, col, row + 1, targetColor, fillColor); // Down
    paintFill(tempFrame, col - 1, row, targetColor, fillColor); // Left
    paintFill(tempFrame, col + 1, row, targetColor, fillColor); // Right
  }

  const ChangeToFill = () => {
    // Set the current mode to fill
    pointerMode = FILL_MODE;
  }

  const ChangeToDraw = () => {
    // Set the current mode to draw
    pointerMode = DRAW_MODE;
  }

  const handleClear = () => {
    const newGrid = [...grid];
    let clearedFrame = newGrid[currentImageIndex];
    for(let i = 0; i < GRID_SIZE; i++) {
      clearedFrame[i] = [GRID_SIZE];
        for(let j = 0; j < GRID_SIZE; j++) {
          clearedFrame[i][j] = [0, 0, 0, 0];
        }
    }

    setGrid(newGrid);
  }

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    // Do something with the selected file...

    // TODO Something went wrong, post an alert
    if (!selectedFile) {
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      if (selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg') {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const tempCanvas = document.createElement('canvas');
          const tempCtx = tempCanvas.getContext('2d');

          // Set the canvas size to GRID_SIZExGRID_SIZE
          tempCanvas.width = GRID_SIZE;
          tempCanvas.height = GRID_SIZE;

          // Draw the scaled-down image on the canvas
          tempCtx.drawImage(img, 0, 0, GRID_SIZE, GRID_SIZE);

          // Convert canvas content to a PNG image (Base64 encoded)
          // const dataURL = tempCanvas.toDataURL('image/png');

          // Optional TODO: Create a new anchor element to trigger download
          // const downloadLink = document.createElement('a');
          // downloadLink.href = dataURL;
          // downloadLink.download = 'downscaled.png';
          // downloadLink.click();

          // Copy the pixels onto the grid
          const imageData = tempCtx.getImageData(0, 0, GRID_SIZE, GRID_SIZE);

          const newGrid = [];
          let frame = [GRID_SIZE];
          newGrid.push(frame);
          for(let i = 0; i < GRID_SIZE; i++) {
            frame[i] = [GRID_SIZE];
              for(let j = 0; j < GRID_SIZE; j++) {
                frame[i][j] = [0, 0, 0, 0];
              }
          }
          for (let y = 0; y < GRID_SIZE; y++) {
            for (let x = 0; x < GRID_SIZE; x++) {
              const index = (y * GRID_SIZE + x) * 4; // RGBA values are stored in groups of 4
              const r = imageData.data[index];
              const g = imageData.data[index + 1];
              const b = imageData.data[index + 2];
              const a = imageData.data[index + 3];
              frame[x][y] = [r, g, b, a];
            }
          }
          tempCanvas.remove();
          imageType = STATIC_IMAGE_TYPE;
          setCurrentImageIndex(0);
          setGrid(newGrid);
        };
      } else if (selectedFile.type === 'video/mp4') {
        setModalProgress(0);
        setModalOpen(true);

        const videoElement = document.createElement('video');

        // Set the video source to the blob URL
        videoElement.src = e.target.result;

        // Wait for the video to load metadata
        videoElement.addEventListener('loadedmetadata', async () => {
          // Calculate the total number of frames based on the video duration
          let videoDuration = Math.floor(videoElement.duration);
          videoElement.currentTime = 0;            // Set to the first frame

          // Temp canvas to downscale with
          const tempCanvas = document.createElement('canvas');
          const tempCtx = tempCanvas.getContext('2d');
          tempCanvas.width = GRID_SIZE;
          tempCanvas.height = GRID_SIZE;

          let newGrid = [];
          let currentFrameIndex = 0;
          while(currentFrameIndex * GIF_RATE < videoDuration) {
            videoElement.currentTime = currentFrameIndex * GIF_RATE;

            // Wait for the video to update
            await new Promise(resolve => videoElement.addEventListener('seeked', resolve));

            // Draw the scaled-down image on the canvas
            tempCtx.drawImage(videoElement, 0, 0, GRID_SIZE, GRID_SIZE);

            // Copy the pixels onto the grid
            const imageData = tempCtx.getImageData(0, 0, GRID_SIZE, GRID_SIZE);

            // Curr frame initialization
            let currFrame = [GRID_SIZE];
            for(let i = 0; i < GRID_SIZE; i++) {
              currFrame[i] = [GRID_SIZE];
              for(let j = 0; j < GRID_SIZE; j++) {
                currFrame[i][j] = [0, 0, 0, 0];
              }
            }
            for (let y = 0; y < GRID_SIZE; y++) {
              for (let x = 0; x < GRID_SIZE; x++) {
                const index = (y * GRID_SIZE + x) * 4; // RGBA values are stored in groups of 4
                const r = imageData.data[index];
                const g = imageData.data[index + 1];
                const b = imageData.data[index + 2];
                const a = imageData.data[index + 3];
                currFrame[x][y] = [r, g, b, a];
              }
            }

            newGrid.push(currFrame);

            currentFrameIndex += 1;

            setModalProgress(Math.ceil((currentFrameIndex * GIF_RATE/videoDuration) * 100))
          }
          totalGIFFrames = newGrid.length;
          tempCanvas.remove();
          imageType = GIF_TYPE;
          setGrid(newGrid);
          handleProgressModalClose();
        });
      } else {
        alert("Unrecognized file type! " + selectedFile.type);
      }
    };

    reader.readAsDataURL(selectedFile);
  };

  const isGIFMode = () => {
    return imageType === GIF_TYPE;
  }

  const handleProgressModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setModalOpen(false)
    }
  }

  return (
    <div className="App">
      <div className="canvas-container">
        <canvas ref={canvasRef} width={canvasSize.width} height={canvasSize.height}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        />
      </div>
      <div className="options-menu">
        <Button name="PublishImage" color='secondary' onClick={PublishImage}>
          Publish Image
        </Button>
        <label htmlFor='docPicker'>
          <input ref={fileInputRef} id='docPicker' name='docPicker' type="file" accept='.png,.jpg,.mp4' onChange={handleFileInputChange} />
          <Button color='secondary' onClick={handleFileButtonClick}>
            Select File
          </Button>
        </label>
        {isGIFMode() && <Slider onChange={OnGIFSliderChange} />}
        <button name="ChangeToRed" onClick={ChangeColor} style={{background:"red"}}>Red</button>
        <button name="ChangeToOrange" onClick={ChangeColor} style={{background:"orange"}}>Orange</button>
        <button name="ChangeToYellow" onClick={ChangeColor} style={{background:"yellow"}}>Yellow</button>
        <button name="ChangeToGreen" onClick={ChangeColor} style={{background:"green"}}>Green</button>
        <button name="ChangeToBlue" onClick={ChangeColor} style={{background:"blue"}}>Blue</button>
        <button name="ChangeToPurple" onClick={ChangeColor} style={{background:"purple"}}>Purple</button>
        <button name="ChangeToWhite" onClick={ChangeColor} style={{background:"white"}}>White</button>
        <button name="ChangeToBlack" onClick={ChangeColor} style={{background:"black",color:"white"}}>Black</button>
        <input id='colorPicker' type="color" onChange={handleCustomColorChange} />
        <button name="ChangeToErase" onClick={ChangeToErase} style={{background:"white"}}>Erase</button>
        <button name="ChangeToFill" onClick={ChangeToFill} style={{background:"white"}}>Fill</button>
        <button name="ChangeToDraw" onClick={ChangeToDraw} style={{background:"white"}}>Draw</button>
        <button name="ClearButton" onClick={handleClear} style={{background:"white"}}>Clear</button>
        <ProgressModal open={modalOpen} onClose={handleProgressModalClose} progress={modalProgress}/>
      </div>
    </div>
  );
}

export default App;