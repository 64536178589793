import React, { useEffect } from 'react';
import { Modal, Backdrop, Fade, LinearProgress } from '@mui/material';

const ProgressModal = ({ open, onClose, progress }) => {
  useEffect(() => {
    
  }, [progress]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div style={{ width: '300px', padding: '20px', background: 'white' }}>
          <h2>Processing...</h2>
          <LinearProgress variant="determinate" value={progress} />
        </div>
      </Fade>
    </Modal>
  );
};

export default ProgressModal;